import store from '../store/index.js'
import Product from "@/packages/Product/src/Models/Product.js"

export default {
  async getProducts() {
      return window.axiosInstance.get('/products').then(response => {
        let products = [];

        for (var i = 0; i < response.data.data.length; i++) {
          let product = new Product(response.data.data[i])
          products.push(product);
          // product.preloadImages();
        }

        store.commit('mutateProducts', products);

        return products;
      }).catch(error => {
        console.log(error);
      })
  },
  async getFeaturedProducts() {
      return window.axiosInstance.get('/products?featured=1', {
        params: {
          featured: true
        }
      }).then(response => {
        let products = [];

        for (var i = 0; i < response.data.data.length; i++) {
          let product = new Product(response.data.data[i])
          products.push(product);
          // product.preloadImages();
        }

        store.commit('mutateFeaturedProducts', products);

        return products;
      }).catch(error => {
        console.log(error);
      })
  },
  getNewProducts() {
      return window.axiosInstance.get('/products?new=1', {
      }).then(response => {
        let products = [];

        for (var i = 0; i < response.data.data.length; i++) {
          let product = new Product(response.data.data[i])
          products.push(product);
          // product.preloadImages();
        }

        store.commit('mutateNewProducts', products);

        return products;
      }).catch(error => {
        console.log(error);
      })
  },

  getProductByURLKey(url_key) {
      return axios.get(process.env.VUE_APP_ROOT_API + '/getProductByURLKey/' + url_key, {
        params: {
          locale: store.state.app.locale.code,
          channel: store.state.app.channel.code,
          currency: store.state.app.currency.code,
        }
      }
      ).then(response => {
        return response.data.data;
      }).catch(error => {
        console.log(error);
      })
  },
  getProductsByCategory(category_id) {
    return axios.get(process.env.VUE_APP_ROOT_API + '/products', {
      params: {
        locale: store.state.app.locale.code,
        channel: store.state.app.channel.code,
        currency: store.state.app.currency.code,
        category_id: category_id
      }
    }
    ).then(response => {
      return response.data.data;
    }).catch(error => {
      console.log(error);
    })
  },
  getProductConfigurableAttributes(productID) {
      return axios.get(process.env.VUE_APP_ROOT_API + '/product-configurable-config/' + productID, {
        params: {
          locale: store.state.app.locale.code,
          channel: store.state.app.channel.code,
          currency: store.state.app.currency.code,
        }
      }
      ).then(response => {
        return response.data.data;
      }).catch(error => {
        console.log(error);
      })
    },
    getProductAdditionalInformation(productID) {
        return axios.get(process.env.VUE_APP_ROOT_API + '/product-additional-information/' + productID, {
          params: {
            locale: store.state.app.locale.code,
            channel: store.state.app.channel.code,
            currency: store.state.app.currency.code,
          }
        }
        ).then(response => {
          return response.data.data;
        }).catch(error => {
          console.log(error);
        })
      }
}
